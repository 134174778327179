.react-datepicker__day--selected,
.react-datepicker__day--selected:hover {
  @apply rounded-full;
}

.react-datepicker__day:hover {
  @apply rounded-full bg-primary bg-opacity-20;
}
.react-datepicker {
  border: none;
}

.react-datepicker__day {
  @apply rounded-full;
}

.react-datepicker__current-month {
  @apply font-effra text-sm font-medium;
}

.react-datepicker__header {
  @apply bg-zinc-100;
}
.react-datepicker-popper,
.react-datepicker__day-name,
.react-datepicker__day {
  @apply font-effra;
}

.react-datepicker__month-container {
  @apply shadow-lg rounded-md border border-black border-opacity-10;
}

.react-datepicker-popper[data-placement^='bottom']
  .react-datepicker__triangle::after,
.react-datepicker-popper[data-placement^='bottom']
  .react-datepicker__triangle::before {
  @apply hidden;
}

.react-datepicker__navigation-icon::before {
  top: 0.75rem;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__day--in-range,
.react-datepicker__day--selected {
  @apply bg-primary;
}

.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range) {
  @apply bg-primary bg-opacity-50;
}

input[type='date']::-webkit-calendar-picker-indicator {
  opacity: 0;
  display: none;
  width: 0;
  height: 0;
  pointer-events: none;
}
